
import {defineComponent, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";


export default defineComponent({

  name: "AddRoleToFactory",
  components: {},
  setup() {
    const addRoleToFactory = ref<null | HTMLFormElement>(null);
    return {
      addRoleToFactory
    }
  },
  data() {
    return {
      factory_id: this.$route.params.idFactory,
      formData: ref({
        id_factory: Number(this.$route.params.idFactory),
        name_isco: "",
        id_isco: Number(""),
        name_nace: "",
        title: "",
        name_kkov: "",
      }),
      rules: {
        title: [
          {
            required: true,
            message: "Název role je povinný",
            trigger: "change",
          },
        ],
        name_isco: [
          {
            required: true,
            message: "Název dle CZ-ISCO je povinný",
            trigger: "change",
          },
        ],
        id_isco: [
          {
            required: true,
            message: "Číslo dle CZ-ISCO je povinný",
            trigger: "change",
          },
        ],
        name_nace: [
          {
            required: true,
            message: "Zařazení dle CZ-NACE je povinný",
            trigger: "change",
          },
        ],
        name_kkov: [
          {
            required: true,
            message: "Vzdělání požadované pro výkon povolání (KKOV) je povinný",
            trigger: "change",
          },
        ],
      },
      loading: false
    }
  },
  methods: {
    submit: function () {
      let formRef
      if (this.$refs.addRoleToFactory) {
        formRef = ref(this.$refs.addRoleToFactory);
      } else return;
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.formData.id_isco = Number(this.formData.id_isco)
          store
            .dispatch(Actions.ADD_ROLE, this.formData)
            .then(() => {
              Swal.fire({
                text: "Nové role",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Zaměstnanec  vytvořen",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {

              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              }).then(function () {

              });
            });
        }
      });
    }
  }
});
