
import {defineComponent, ref, onMounted} from "vue";
import AddFatoryRoleForm from "@/components/forms/addFactoryRole.vue"


export default defineComponent({
  name: "TestComponet",
  components: {
    AddFatoryRoleForm,
  },

});
